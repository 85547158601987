<template>
  <div class="box">
    <div class="info">
      <div class="header">
        <div class="title">【智摆官网建站】 更新日志</div>
        <div class="tips">
          我们致力于通过解决错误并经常在每个版本中实现新功能来不断改进智摆官网。
          <br />
          您可以在下面找到我们最新的 智摆官网 更新说明。
        </div>
      </div>
      <div class="body">
        <ul>
          <li>
            <div class="tail"></div>
            <div class="head"></div>
            <div class="contents">
              <div class="time">
                <div class="time-ver">v1.2.5</div>
                <div class="time-time">发布于2023-01-05 14:00:51</div>
              </div>
              <div class="body-content">
                <div class="content-wrap">
                  <div class="title">
                    <div class="icon">
                      <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673601395712ce.png" />
                    </div>
                    <div class="text">新增功能</div>
                  </div>
                  <ul>
                    <li>
                      <div class="tag add">新增</div>
                      <div class="text">【卡片】新增页面通用的30+种展示卡片；</div>
                    </li>
                  </ul>
                </div>
                <div class="content-wrap">
                  <div class="title">
                    <div class="icon">
                      <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673601621857ce.png" />
                    </div>
                    <div class="text">优化功能</div>
                  </div>
                  <ul>
                    <li>
                      <div class="tag optimization">优化</div>
                      <div class="text">【视觉】优化动画、界面视觉冲击感；</div>
                    </li>
                  </ul>
                </div>
                <div class="content-wrap">
                  <div class="title">
                    <div class="icon">
                      <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673601797898ce.png" />
                    </div>
                    <div class="text">修复功能</div>
                  </div>
                  <ul>
                    <li>
                      <div class="tag repair">修复</div>
                      <div class="text">【修复】页面跳转问题、板块问题；</div>
                    </li>
                    <li>
                      <div class="tag repair">修复</div>
                      <div class="text">【修复】已知的问题bug；</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="tail"></div>
            <div class="head"></div>
            <div class="contents">
              <div class="time">
                <div class="time-ver">v1.1.12</div>
                <div class="time-time">发布于2022-11-12 09:20:35</div>
              </div>
              <div class="body-content">
                <div class="content-wrap">
                  <div class="title">
                    <div class="icon">
                      <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673601395712ce.png" />
                    </div>
                    <div class="text">新增功能</div>
                  </div>
                  <ul>
                    <li>
                      <div class="tag add">新增</div>
                      <div class="text">新增卡片50种展示卡片</div>
                    </li>
                    <li>
                      <div class="tag add">新增</div>
                      <div class="text">特色功能20种功能</div>
                    </li>
                    <li>
                      <div class="tag add">新增</div>
                      <div class="text">帮助中心功能模块</div>
                    </li>
                    <li>
                      <div class="tag add">新增</div>
                      <div class="text">新增特色跳转功能</div>
                    </li>
                    <li>
                      <div class="tag add">新增</div>
                      <div class="text">各类产品展示26种</div>
                    </li>
                  </ul>
                </div>
                <div class="content-wrap">
                  <div class="title">
                    <div class="icon">
                      <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673601621857ce.png" />
                    </div>
                    <div class="text">优化功能</div>
                  </div>
                  <ul>
                    <li>
                      <div class="tag optimization">优化</div>
                      <div class="text">【界面】界面排版优化</div>
                    </li>
                  </ul>
                </div>
                <div class="content-wrap">
                  <div class="title">
                    <div class="icon">
                      <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673601797898ce.png" />
                    </div>
                    <div class="text">修复功能</div>
                  </div>
                  <ul>
                    <li>
                      <div class="tag repair">修复</div>
                      <div class="text">【修复】修复已知bug</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  padding: 20px;
  background-color: #f4f6f8;
  .info {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding: 40px 120px;
    .header {
      color: #262b30;
      .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 34px;
        color: #262b30;
      }
      .tips {
        font-size: 14px;
        line-height: 28px;
        margin-top: 20px;
      }
    }
    .body {
      padding-top: 60px;
      ul {
        li {
          margin: 0;
          padding: 0 0 12px 0;
          list-style: none;
          position: relative;
          &:last-child {
            .tail {
              display: none;
            }
          }
          .tail {
            height: 100%;
            position: absolute;
            left: 6px;
            top: 0;
            border-left: 1px solid #f2ca8f;
          }
          .head {
            width: 13px;
            height: 13px;
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #ffd89f;
            position: absolute;
          }
          .head-blue {
          }
          .contents {
            padding: 1px 1px 10px 24px;
            font-size: 14px;
            position: relative;
            top: -3px;
            padding-left: 54px;
            .time {
              padding-bottom: 20px;
              border-bottom: 1px solid #e9edef;
              .time-ver {
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #262b30;
              }
              .time-time {
                font-size: 12px;
                line-height: 16px;
                color: #939799;
                margin-top: 10px;
              }
            }
            .body-content {
              padding-top: 20px;
              .content-wrap {
                color: #262b30;
                font-size: 14px;
                line-height: 20px;
                padding-bottom: 20px;
                .title {
                  display: flex;
                  align-items: center;
                  font-weight: bold;
                  .icon {
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    img {
                      border-style: none;
                    }
                  }
                }
                ul {
                  li {
                    display: flex;
                    margin-top: 20px;
                    .tag {
                      width: 40px;
                      height: 20px;
                      border-radius: 2px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 12px;
                      line-height: 16px;
                      margin-right: 10px;
                      flex-shrink: 0;
                      &.add {
                        color: #197be1;
                        background: #e8eeff;
                      }
                      &.optimization {
                        color: #19be6b;
                        background: #edfff3;
                      }
                      &.repair {
                        color: #ff9900;
                        background: #fff9e6;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .footer-page {
        padding: 20px 0;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
